<template>
  <div class="StripeCheckout">
    <!-- <a v-if="component.use_paddle" href="#!" class="paddle_button" data-product="12345">Buy Now!</a> -->
    <div v-if="component.use_paddle" class="checkout-container"></div>
    <div v-else-if="useVanillaStripe">
      <button
        :class="_elSel('StripeCheckoutButton', ['Main'])"
        type="button"
        id="submit"
        :disabled="actionCompletedOrInProgress"
        @click.prevent.stop="openVanillaElements"
      >
        <template>{{ submitButtonText }}</template>
        <Icon name="chevron-right" />
      </button>
      <div class="payment-messages">
        <div v-if="errorMessage || isNotHTTPS" class="error-message">
          <Icon name="exclamation-triangle" />
          <div>{{ errorMessage }}</div>
          <div v-if="isNotHTTPS">
            To make things secure, we only accept payments via HTTPS, not HTTP - please reload this
            using HTTPS.
          </div>
        </div>
        <div v-if="status === 'paid' || status === 'card_added'" class="success-message">
          <Icon name="check-circle" />
          <template v-if="status === 'paid'">
            Your{{ isInWebApp ? ' TEST ' : ' ' }}payment of {{ currencySymbol }}{{ amount }} has
            been received!
          </template>
          <template v-else-if="status === 'card_added'">
            Your{{ isInWebApp ? ' TEST ' : ' ' }}information has been saved!
          </template>
        </div>
      </div>
    </div>
    <form v-else id="payment-form" ref="form" @submit.prevent="onClick">
      <template v-if="!hideCCFields">
        <div v-if="!useDirectBridge" class="payment-box" :key="pkey">
          <stripe-elements
            ref="stripe"
            @token="onToken"
            @error="onError"
            @change="onChange"
            :publishable-key="pkey"
          />
        </div>
        <div v-else ref="paymentContainer"></div>
      </template>
      <div
        class="payment-box-apple-google"
        ref="altPaymentContainer"
        v-if="!collectDetailsOnly && clientSecret && useAltPayment && !useDirectBridge"
        :key="`${pkey}_alt`"
      >
        <stripe-payment-request
          v-if="!useDirectBridge"
          style="min-width: 100%; margin-left: -12px; margin-right: -12px;"
          :publishable-key="pkey"
          generate="payment-method"
          :client-secret="clientSecret"
          request-payer-name
          request-payer-email
          request-payer-phone
          :amount="amount * 100"
          label="label"
          :country="component.country"
          :currency="currency"
          @payment-method="onPaymentMethod"
          @error="onError"
          @unsupported="onUnsupportedError"
        />
      </div>
      <div
        v-else-if="useDirectBridge"
        class="payment-box-apple-google"
        ref="altPaymentContainer"
      ></div>
      <!-- <button
        :class="_elSel('StripeCheckoutButtonAlternate', ['Alternate'])"
        v-if="showAltButton"
        type="button"
        @click="setupAltPayment"
      >
        Use other payment method (Apple Pay, Google Pay, etc)
      </button> -->
      <button
        v-if="!errorIsBlocking && !hideCCFields"
        :class="[_elSel('StripeCheckoutButton', ['Main']), { CardComplete: isPotentiallyComplete }]"
        :disabled="actionCompletedOrInProgress"
        id="submit"
      >
        <template v-if="localStatus === 'pending'">
          <Icon class="fa-spin" name="spinner" /> Please wait...
        </template>
        <template v-else>
          <template>{{ submitButtonText }}</template>
          <!-- <template v-if="component.pay_button_text">{{ component.pay_button_text }}</template>
          <template v-else-if="collectDetailsOnly">Save Card Details </template>
          <template v-else> Pay ${{ amount }} </template> -->
          <Icon name="chevron-right" />
        </template>
      </button>
      <div class="payment-messages">
        <div v-if="errorMessage || isNotHTTPS" class="error-message">
          <Icon name="exclamation-triangle" />
          <div>{{ errorMessage }}</div>
          <div v-if="isNotHTTPS">
            To make things secure, we only accept payments via HTTPS, not HTTP - please reload this
            using HTTPS.
          </div>
        </div>
        <div v-if="status === 'paid' || status === 'card_added'" class="success-message">
          <Icon name="check-circle" />
          <template v-if="status === 'paid'">
            Your{{ isInWebApp ? ' TEST ' : ' ' }}payment of {{ currencySymbol }}{{ amount }} has
            been received!
          </template>
          <template v-else-if="status === 'card_added'">
            Your{{ isInWebApp ? ' TEST ' : ' ' }}information has been saved!
          </template>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
/* global Stripe */

import api from '@/helpers/flowApi'
import computedValues from './editor/helpers/computedValues'
import '@power-elements/stripe-elements'
// import * as Sentry from '@sentry/vue'
import conversionTrack from '@/components/form/helpers/conversionTrack'
import notify from '@/helpers/notifyLite'

import Bowser from 'bowser'
const browser = Bowser.getParser(window.navigator.userAgent)

const isSafari14 = browser.satisfies({ safari: '~14.1' })
// const isSafari14Five = browser.satisfies({ safari: '>=14.5' })
// const isNotSafari15 = !browser.satisfies({ safari: '~15' })
const ERROR_CHANNEL = 'C02QC92CGM8'
export default {
  inject: {
    _isInWebApp: { default: () => () => {} },
    _getFlowVersionNumber: { default: () => () => {} },
    _setCheckpoint: { default: () => () => {} },
    _sendIndividualDataOutput: { default: () => () => {} },
    _elSel: { default: () => () => [] },
    _submitForm: { default: () => () => [] },
  },
  props: { component: Object, userData: Object, form: Object, pageMeetsRequirements: Boolean },
  data() {
    return {
      localStatus: 'ready',
      errorMessage: '',
      clientSecret: null,
      customerId: null,
      errorIsBlocking: null,
      token: null,
      error: null,
      useAltPayment: false,
      attemptedAltPaymentLoad: false,
      /* Test */
      listener: null,
      isSetupIntent: false,
      cardComplete: false,
      cardExpiryComplete: false,
      cardCvcComplete: false,
    }
  },
  computed: {
    collectDetailsOnly() {
      return (
        this.component.action === 'collect_details' ||
        (this.component && this.component.collect_details)
      )
    },
    pkey() {
      return this.isInWebApp
        ? process.env.VUE_APP_STRIPE_TEST_PUBLIC_KEY
        : this.component && this.component.publishable_key
    },
    isInWebApp() {
      return this._isInWebApp()
    },
    currency() {
      return this.component.currency || 'usd'
    },
    currencySymbol() {
      switch (this.currency) {
        case 'gbp':
          return '£'
        case 'eur':
          return '€'
        case 'usd':
        case 'cad':
        default:
          return '$'
      }
    },
    amount() {
      return (
        (typeof this.component.amount === 'number' &&
          (parseInt(this.component.amount) === this.component.amount
            ? parseInt(this.component.amount)
            : this.component.amount.toFixed(2))) ||
        0
      )
    },
    isNotHTTPS() {
      return !this.isInWebApp && window.location.protocol !== 'https:'
    },
    label() {
      return this.component.label || 'Pay with Wallet'
    },
    name() {
      return (
        computedValues(this.userData, this.component.billing_info_name || '', this.form) ||
        undefined
      )
    },
    email() {
      return (
        computedValues(this.userData, this.component.billing_info_email || '', this.form) ||
        undefined
      )
    },
    phone() {
      return (
        computedValues(this.userData, this.component.billing_info_phone || '', this.form) ||
        undefined
      )
    },
    submitButtonText() {
      if (this.component.pay_button_text)
        return computedValues(this.userData, this.component.pay_button_text || '', this.form)
      else if (this.collectDetailsOnly) return `Save Card Details`
      return `Pay ${this.currencySymbol}${this.amount}`
    },
    status() {
      return this.userData[this.component.key]
    },
    actionCompletedOrInProgress() {
      const status = this.collectDetailsOnly ? this.status === 'card_added' : this.status === 'paid'
      const localStatus = this.localStatus === 'pending' || this.localStatus === 'success'
      return status || localStatus
    },
    useVanillaStripe() {
      if (this.useDirectBridge) return false
      /* TODO - User Agent for Safari 14.1 */
      const versionCheck = isSafari14 // || (isSafari14Five && isNotSafari15)
      return versionCheck || (this.component && this.component.use_vanilla_stripe)
    },
    // showAltButton() {
    /* Commented out: https://heysavvy.slack.com/archives/DLNE5DAJ1/p1644599194026849 */
    //   if (this.component.allow_alternate_payments && this.component.hide_safeguard_button)
    //     return this.attemptedAltPaymentLoad === false ? false : !Boolean(this.clientSecret)
    //   return this.component.allow_alternate_payments
    // },
    useDirectBridge() {
      return this.component.use_direct_bridge || false
    },
    isPotentiallyComplete() {
      return this.component.individual_fields
        ? this.cardComplete && this.cardCvcComplete && this.cardExpiryComplete
        : this.cardComplete
    },
    hideCCFields() {
      return this.component.allow_alternate_payments && this.component.hide_cc_fields
    },
  },
  watch: {
    errorMessage: {
      handler(v) {
        if (this.useVanillaStripe) this.setVanillaMessage(v.errorMessage || v)
      },
    },
    localStatus: {
      handler(v) {
        switch (v) {
          case 'success': {
            this.setVanillaMessage('')
            break
          }
          case 'ready': {
            this.setVanillaMessage(this.errorMessage || '')
            break
          }
          case 'pending': {
            /* Loading message */
            this.setVanillaMessage('Loading...')
            break
          }

          default: {
            break
          }
        }
      },
    },
    errorIsBlocking: {
      handler(e) {
        if (e && !this.isInWebApp) {
          const contextMessage = this.pkey
            ? this.collectDetailsOnly
              ? '4XX / 5XX error in storing card data'
              : '4XX / 5XX error in making a payment'
            : 'Publishable Key is missing'
          const message = `A blocking error occurred in Flow ${this.form.id}\n${contextMessage}`
          notify(message, e, { form: this.form, userData: this.userData, channel: ERROR_CHANNEL })
          // Sentry.captureMessage(message)
        }
      },
      immediate: true,
    },
    pkey: {
      handler(pkey) {
        if (!pkey) {
          this.errorIsBlocking = true
          this.errorMessage =
            'Secure payments is configured incorrectly. Please contact us and let us know!'
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.component.use_paddle) {
      window.Paddle.Environment.set('sandbox')
      window.Paddle.Setup({ vendor: this.component.paddle_vendor_id })
      window.Paddle.Checkout.open({
        method: 'inline',
        product: this.component.paddle_product_id, // Replace with your Product or Plan ID
        allowQuantity: false,
        disableLogout: true,
        frameTarget: 'checkout-container', // The className of your checkout <div>
        frameInitialHeight: 416,
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;', // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      })
    }

    if (!this.hideCCFields) {
      if (this.useVanillaStripe) {
        this.elSetup = this.setUpElements()

        // const self = this
        // this.$nextTick(() => {
        //   self.openVanillaElements()
        // })
      } else if (this.useDirectBridge) {
        this.initializeCardElements()
      }
    }
    if (this.component.allow_alternate_payments) {
      this.setupAltPayment()
    }
  },
  beforeDestroy() {
    this.closeVanillaElements(true)
    Object.values(this.cleanUpFns || {}).forEach(fn => {
      if (typeof fn === 'function') fn()
    })
  },
  methods: {
    async setupAltPayment() {
      this.useAltPayment = true
      try {
        if (this.useDirectBridge) {
          await this.$nextTick()
          await this.initializeWallet()
        } else if (!this.clientSecret) {
          this.clientSecret = await this.getClientSecret()
        }
      } catch (error) {
        this.useAltPayment = false
      }
      this.attemptedAltPaymentLoad = true
    },
    onUnsupportedError() {
      console.warn('Alternative Payment Methods for Stripe are not supported')
      // this.errorMessage = 'Alternative Payment Methods are unsupported on this device'
    },
    onChange({ detail }) {
      if (detail.hasOwnProperty('complete')) this.cardComplete = detail.complete
    },
    onError(e, skipSet) {
      if (!skipSet) this.errorMessage = e.error ? e.error.message : ''
      this.localStatus = 'ready'
      if (
        (e.error && e.error.type === 'validation_error') ||
        (e.error && e.error.type === 'card_error')
      )
        return
      const slackMessage = `Stripe Error\n\ncode: ${(e.error && e.error.code) ||
        'n/a'}\ntype: ${(e.error && e.error.type) ||
        e.message ||
        JSON.stringify(e)}\nmessage: ${this.errorMessage ||
        e.message ||
        JSON.stringify(e)}\nerror:${JSON.stringify(e.error)}`

      notify(slackMessage, e.error, {
        form: this.form,
        userData: this.userData,
        channel: ERROR_CHANNEL,
      })
    },
    async onClick(cardElements) {
      if (this.actionCompletedOrInProgress) return
      this.localStatus = 'pending'
      try {
        if (this.$refs.stripe) this.$refs.stripe.createToken()
        else if (this.useVanillaStripe) {
          // const token = await this.stripePackage.createToken(cardElements)
          // console.log(`thef token`, token)
          this.onToken(
            {
              detail: {
                card: cardElements,
                billing_details: { name: this.name, email: this.email, phone: this.phone },
              },
            },
            true
          )
        } else if (this.useDirectBridge) {
          this.onToken(
            {
              detail: {
                card: this.cardElement,
                billing_details: { name: this.name, email: this.email, phone: this.phone },
              },
            },
            true
          )
        }
      } catch (error) {
        // if (!this.isInWebApp) Sentry.captureException(error)
        this.localStatus = 'ready'
        console.error(error)
        this.errorMessage = error.message
      }
    },
    async onSuccess() {
      this.localStatus = 'success'
      this.errorMessage = ''
      this.clientSecret = null
      const successStatus = this.collectDetailsOnly || this.isSetupIntent ? 'card_added' : 'paid'
      this.$emit('update', [this.component.key || this.componentId, successStatus])
      if (this.component.next_on_complete) {
        await this.$nextTick()
        this.$emit('next')
      }
      this.closeVanillaElements()

      const checkpoints = this.component.on_payment_complete_checkpoints
      if (Array.isArray(checkpoints) && checkpoints.length > 0) {
        this._setCheckpoint(checkpoints)
      }
      if (this.component.on_payment_complete_conversions) {
        conversionTrack(this.component, this.userData, this.form, 'on_payment_complete_conversions')
      }
      const outputs = this.component.on_payment_complete_outputs
      const now = new Date().toISOString()
      if (Array.isArray(outputs)) outputs.forEach(k => this._sendIndividualDataOutput(k, now))
    },
    onPaymentMethod() {
      this.onSuccess()
    },
    async onToken({ detail: token }, setPaymentMethod) {
      if (!this.isInWebApp && window.location.protocol !== 'https:') {
        this.localStatus = 'ready'
        return
      }
      if (this.component.enforce_validation && !this.pageMeetsRequirements) {
        this._submitForm()
        this.localStatus = 'ready'
        return
      }
      const publishableKey = this.pkey
      const stripe = this.stripePackage || Stripe(publishableKey)
      this.stripePackage = stripe
      const tokenData = token
      try {
        const clientSecret = this.clientSecret || (await this.getClientSecret())
        if (!clientSecret) {
          this.localStatus = 'ready'
          return
        }

        const data = {
          payment_method:
            setPaymentMethod === true
              ? token
              : {
                  card: { token: tokenData.id },
                  billing_details: { name: this.name, email: this.email, phone: this.phone },
                },
        }

        const result =
          this.collectDetailsOnly || this.isSetupIntent
            ? await stripe.confirmCardSetup(clientSecret, data)
            : await stripe.confirmCardPayment(clientSecret, data)

        if (result.error) {
          this.localStatus = 'ready'
          // Show error to your customer (e.g., insufficient funds)
          this.errorMessage = result.error.message
          this.$emit('update', [this.component.key || this.componentId, 'failed'])
          console.error(result.error.message)
          this.onError(result)
          return result
        } else {
          // The payment has been processed!
          const intent = result.paymentIntent || result.setupIntent
          if (this.collectDetailsOnly || (intent && intent.status === 'succeeded')) {
            try {
              const addPaymentMethodToUserData = Boolean(
                this.component.output_payment_method && this.component.payment_method_key
              )
              const paymentMethod =
                result && result.setupIntent && result.setupIntent.payment_method
              if (this.collectDetailsOnly && addPaymentMethodToUserData && paymentMethod) {
                this.$emit('update', [this.component.payment_method_key, paymentMethod])
                await this.$nextTick()
              }
            } catch (error) {
              console.error(error)
            }
            this.onSuccess()
          } else this.$emit('update', [this.component.key || this.componentId, 'no_error'])
          return result
        }
      } catch (error) {
        this.localStatus = 'ready'
        this.errorMessage =
          (error && error.response && error.response.data) ||
          error.message ||
          'Something went wrong internally - please refresh and try again. If the problem persists, let us know!'
        console.error(error)
        // if (!this.isInWebApp) Sentry.captureException(error)
        this.$emit('update', [this.component.key || this.componentId, 'failed'])
        if (error.response && error.response.data) console.error(error.response.data)
        try {
          this.onError(error, true)
        } catch (error1) {
          try {
            let slackMessage = e => `Stripe Error\n\n: ${(e && e.response?.data) || e.message}`

            notify(slackMessage(error), error, {
              form: this.form,
              userData: this.userData,
              channel: ERROR_CHANNEL,
            })
            notify(slackMessage(error1), error1, {
              form: this.form,
              userData: this.userData,
              channel: ERROR_CHANNEL,
            })
          } catch (error) {
            try {
              notify('Slack issue - next-to-last resort', error1, {
                form: this.form,
                userData: this.userData,
                channel: ERROR_CHANNEL,
              })
            } catch (error) {
              notify('Slack issue - last resort', null, {
                form: this.form,
                userData: this.userData,
                channel: ERROR_CHANNEL,
              })
            }
          }
        }
      }
    },
    async getClientSecret() {
      const formId = this.form.id

      const apidata = {
        formId,
        componentId: this.component.id,
        webapp: this.isInWebApp,
        flowVersion: this._getFlowVersionNumber(),
      }

      const shouldAddContactInfo =
        this.collectDetailsOnly ||
        this.component.action === 'subscription' ||
        (this.component.action === 'one-time-payment' && this.component.create_customer)
      if (shouldAddContactInfo) {
        apidata.details = { name: this.name, email: this.email, phone: this.phone }
      }
      const shouldAddPaymentDetails = !(
        this.collectDetailsOnly || this.component.action === 'subscription'
      )
      if (shouldAddPaymentDetails) {
        apidata.amount = +this.amount
        apidata.currency = this.component.currency
      }
      if (this.component.action === 'subscription') {
        const computeArray = arr =>
          Array.isArray(arr)
            ? arr.map(v => computedValues(this.userData, v, this.form))
            : computedValues(this.userData, arr, this.form)
        apidata.price_id = computeArray(this.component.price_id)
        if (this.component.promo_code) apidata.promo_code = computeArray(this.component.promo_code)
        if (this.component.one_time_price_id)
          apidata.one_time_price_id = computeArray(this.component.one_time_price_id)
        if (this.component.trial_period_days)
          apidata.trial_period_days = computedValues(
            this.userData,
            this.component.trial_period_days,
            this.form
          )
      }

      const url = this.collectDetailsOnly
        ? `/public/billing/flow-setup-intent`
        : this.component.action === 'subscription'
        ? `/public/billing/set-up-subscription`
        : `/public/billing/create-payment-intent`

      try {
        const { data } = await api(url, apidata)
        if (!data.success) {
          this.errorMessage = data.errorMessage
          return null
        }
        if (
          this.component.output_customer_id &&
          this.component.customer_id_key &&
          data.customer_id
        ) {
          this.$emit('update', [this.component.customer_id_key, data.customer_id])
          await this.$nextTick()
        }
        const clientSecret = data.intent_client_secret
        this.clientSecret = clientSecret
        this.isSetupIntent = data.is_setup_intent || false
        return clientSecret
      } catch (error) {
        // if (!this.isInWebApp) Sentry.captureException(error)
        console.error(error)

        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.errorMessage || error.response.data
        } else {
          this.errorIsBlocking = true
          this.errorMessage =
            'Something went wrong internally - please refresh and try again. If the problem persists, let us know!'
        }
      }
    },
    setVanillaMessage(text) {
      if (this.elSetup && typeof this.elSetup.setMessage === 'function') {
        this.elSetup.setMessage(text)
      }
    },
    openVanillaElements() {
      if (this.elSetup && typeof this.elSetup.show === 'function') {
        this.elSetup.show(true)
      }
    },
    closeVanillaElements(destroy) {
      if (destroy) {
        if (this.elSetup && typeof this.elSetup.destroy === 'function') {
          this.elSetup.destroy()
          this.elSetup = null
        }
      } else {
        if (this.elSetup && typeof this.elSetup.show === 'function') {
          this.elSetup.show(false)
        }
      }
    },
    setUpElements() {
      const stripe = this.stripePackage || Stripe(this.pkey)
      this.stripePackage = stripe
      const elements = stripe.elements()
      const styleObjToString = obj =>
        Object.entries(obj).reduce((acc, [key, value]) => `${acc}${key}:${value};`, '')
      const style = {}

      const mask = document.createElement('div')
      const baseMaskStyle = {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        'z-index': 999,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'background-color': 'rgba(0, 0, 0, 0.25)',
      }
      mask.style = styleObjToString({ ...baseMaskStyle, visibility: 'hidden' })

      const popupEl = document.createElement('div')
      popupEl.style = styleObjToString({
        'max-width': '600px',
        padding: '10px',
        'background-color': 'white',
        width: '90vw',
        'border-radius': '5px',
        position: 'relative',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
      })

      const formContainer = document.createElement('div')
      formContainer.style = styleObjToString({
        'max-width': '90%',
        'background-color': 'white',
        width: '500px',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
      })

      const cancelButton = document.createElement('div')
      cancelButton.style = styleObjToString({
        position: 'absolute',
        top: '0',
        right: '5px',
        cursor: 'pointer',
        padding: '10px',
      })
      cancelButton.innerText = 'x'
      cancelButton.addEventListener('click', () => this.closeVanillaElements())

      const form = document.createElement('form')
      form.id = 'payment-form'
      form.style = styleObjToString({
        width: '440px',
        'max-width': '100%',
        padding: '20px 0',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'stretch',
      })

      const title = document.createElement('div')
      title.style = styleObjToString({
        'font-size': '20px',
        margin: '10px 0',
      })
      title.innerText = 'Enter your card details below:'

      const cardEl = document.createElement('div')
      cardEl.id = 'card-element'
      cardEl.style = styleObjToString({
        margin: '10px 0',
        padding: '15px 20px',
        'background-color': 'white',
        width: '100%',
        'max-width': '440px',
        'border-radius': '6px',
        'box-shadow':
          '0 13px 27px -5px rgb(50 50 93 / 13%), 0 8px 16px -8px rgb(0 0 0 / 10%), 0 -6px 16px -6px rgb(0 0 0 / 3%)',
      })

      // cardEl.style = styleObjToString({})

      const submitButton = document.createElement('button')
      submitButton.id = 'form-submit'
      // submitButton.type = 'submit'
      submitButton.innerText = this.submitButtonText
      submitButton.style = styleObjToString({
        margin: '10px 0',
        padding: '12px 20px',
        width: '100%',
        'max-width': '440px',
        background: '#6454f3',
        color: '#fff',
        'border-radius': '6px',
        border: 'none',
        cursor: 'pointer',
      })

      const cardErrorsEl = document.createElement('div')
      cardErrorsEl.id = 'card-errors'
      cardErrorsEl.role = 'alert'

      form.appendChild(title)
      form.appendChild(cardEl)
      form.appendChild(cardErrorsEl)
      form.appendChild(submitButton)

      formContainer.appendChild(form)

      popupEl.appendChild(cancelButton)
      popupEl.appendChild(formContainer)

      mask.appendChild(popupEl)

      document.body.appendChild(mask)
      const card = elements.create('card', { style })
      const onSubmitCallback = async e => {
        if (e && e.preventDefault) e.preventDefault()
        await this.$nextTick()
        if (submitButton.disabled) {
          return
        } else {
          this.onClick(card)
        }
      }
      form.addEventListener('submit', onSubmitCallback)
      submitButton.addEventListener('click', async e => {
        e.preventDefault()
        e.stopPropagation()
        if (form.requestSubmit) {
          form.requestSubmit(submitButton)
        } else {
          await onSubmitCallback()
        }
      })
      card.on('change', cardObj => {
        if (cardObj && cardObj.error) {
          this.onError(cardObj)
          submitButton.disabled = true
        } else {
          this.errorMessage = ''
          submitButton.disabled = false
        }
      })
      card.mount(cardEl)
      const changeMaskVisibility = on => {
        mask.style = styleObjToString({ ...baseMaskStyle, visibility: on ? 'visible' : 'hidden' })
      }
      const setMessage = text => (cardErrorsEl.innerText = text)
      return {
        maskEl: mask,
        show: changeMaskVisibility,
        setMessage,
        destroy: () => {
          card.unmount()
          mask.remove()
        },
      }
    },
    async setUpBridgeElement(containerName = 'paymentContainer') {
      const styleObjToString = obj =>
        Object.entries(obj).reduce((acc, [key, value]) => `${acc}${key}:${value};`, '')

      this.cleanUpFns = this.cleanUpFns || {}
      const cleanupFnName = `_clean${containerName}`
      if (typeof this.cleanUpFns[cleanupFnName] === 'function') this.cleanUpFns[cleanupFnName]()
      const bridgeId =
        containerName !== 'paymentContainer'
          ? `stripe-container-${this.component.id}-${containerName}`
          : `stripe-container-${this.component.id}`

      const existingBridges = document.querySelectorAll(`#${bridgeId}`)
      if (existingBridges.length) existingBridges.forEach(el => el.remove())

      const bridge = document.createElement('div')
      bridge.id = bridgeId

      bridge.className = 'payment-box'
      bridge.style = styleObjToString({
        background: '#fff',
        '-webkit-box-shadow':
          '0 13px 27px -5px rgb(50 50 93 / 13%), 0 8px 16px -8px rgb(0 0 0 / 10%), 0 -6px 16px -6px rgb(0 0 0 / 3%)',
        'box-shadow':
          '0 13px 27px -5px rgb(50 50 93 / 13%), 0 8px 16px -8px rgb(0 0 0 / 10%), 0 -6px 16px -6px rgb(0 0 0 / 3%)',
        'border-radius': '6px',
        padding: '12px',
        margin: '10px 0',
      })

      const containerEl = this.$refs[containerName].$el || this.$refs[containerName]

      /* This fails if savvy-flow is inside of an iframe or another shadow DOM. */
      const flowWc = document.querySelector(`savvy-flow[id="${this.form.id}"]`)
      if (!this.isInWebApp || flowWc) {
        bridge.slot =
          containerName !== 'paymentContainer'
            ? `stripe-${this.component.id}-${containerName}`
            : `stripe-${this.component.id}`
        flowWc.appendChild(bridge)

        const slot = document.createElement('slot')
        slot.name = bridge.slot
        containerEl.appendChild(slot)
      } else {
        containerEl.appendChild(bridge)
      }

      this.cleanUpFns[cleanupFnName] = () => {
        bridge.remove()
        containerEl.innerHTML = ''
        this.cleanUpFns[cleanupFnName] = null
      }
      await this.$nextTick()
      return bridge.id
    },
    async initializeWallet() {
      const bridgeId = await this.setUpBridgeElement('altPaymentContainer')
      const stripe = this.stripePackage || Stripe(this.pkey)
      this.stripePackage = stripe
      const elements = stripe.elements()
      const style = { base: { padding: '12px' } }

      const paymentRequest = stripe.paymentRequest({
        country: this.component.country,
        currency: this.currency,
        total: { label: this.label, amount: this.amount * 100 },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      })

      paymentRequest.on('paymentmethod', async ev => {
        const result = await this.onToken({ detail: ev.paymentMethod.id }, true)
        if (!result || result.error) {
          ev.complete('fail')
        } else ev.complete('success')
      })

      const canMakePayment = await paymentRequest.canMakePayment()
      if (canMakePayment) {
        const wallet = elements.create('paymentRequestButton', { paymentRequest, style })
        wallet.mount(`#${bridgeId}`)
        this.walletElement = wallet
      } else {
        console.warn('Wallet Payments not supported')
      }

      //   @payment-method="onPaymentMethod"
      //   @error="onError"
      //   @unsupported="onUnsupportedError"
    },
    async initializeCardElements() {
      const bridgeId = await this.setUpBridgeElement()
      const stripe = this.stripePackage || Stripe(this.pkey)
      this.stripePackage = stripe
      const elements = stripe.elements()
      const style = { base: { padding: '12px' } }

      if (this.component.individual_fields) {
        const bridge = document.getElementById(bridgeId)

        /* TODO - Put widths on these containers */
        const cardNumberContainer = document.createElement('div')
        cardNumberContainer.id = `${bridgeId}-number`
        cardNumberContainer.className = 'stripe-card-container'
        cardNumberContainer.style = 'width: 60%; position: relative;'
        const cardExpiryContainer = document.createElement('div')
        cardExpiryContainer.id = `${bridgeId}-expiry`
        cardExpiryContainer.className = 'stripe-card-container'
        cardExpiryContainer.style = 'width: 20%;'
        const cardCvcContainer = document.createElement('div')
        cardCvcContainer.id = `${bridgeId}-cvc`
        cardCvcContainer.className = 'stripe-card-container'
        cardCvcContainer.style = 'width: 20%;'

        bridge.appendChild(cardNumberContainer)
        bridge.appendChild(cardExpiryContainer)
        bridge.appendChild(cardCvcContainer)

        const cardNumber = elements.create('cardNumber', { style })
        cardNumber.on('change', e => {
          if (e.hasOwnProperty('complete')) this.cardComplete = e.complete
        })
        const cardCvc = elements.create('cardCvc', { style })
        cardCvc.on('change', e => {
          if (e.hasOwnProperty('complete')) this.cardCvcComplete = e.complete
        })
        const cardExpiry = elements.create('cardExpiry', { style })
        cardExpiry.on('change', e => {
          if (e.hasOwnProperty('complete')) this.cardExpiryComplete = e.complete
        })

        cardNumber.mount(`#${cardNumberContainer.id}`)
        cardCvc.mount(`#${cardCvcContainer.id}`)
        cardExpiry.mount(`#${cardExpiryContainer.id}`)

        if (this.component.cc_field_logos_image) {
          const logosImg = document.createElement('img')
          logosImg.id = `${bridgeId}-logos-image`
          logosImg.src = this.component.cc_field_logos_image
          logosImg.style =
            'position: absolute; right: 16px; height: 22px; top: 0; bottom: 0; margin: auto;'
          cardNumberContainer.appendChild(logosImg)
        }

        this.cardElement = cardNumber
      } else {
        const card = elements.create('card', { style })
        card.on('change', e => {
          if (e.hasOwnProperty('complete')) this.cardComplete = e.complete
        })
        card.mount(`#${bridgeId}`)
        this.cardElement = card
      }
    },
  },
}
</script>
